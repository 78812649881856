import { POLYGON_ADDRESSES } from "./addresses";
import {
  getERC20,
  useERC20,
 // useBurnSwap,
  getBurnSwap,
  getQuickSwapRouter,
  useBurnPage,
  getBurnPage
} from "./contracts";
import { useCalls, useContractFunction, useEthers } from "@usedapp/core";

import { PromiseOrValue } from "./__generated__/common";
import * as React from 'react'
import { useState, useMemo, useEffect } from 'react'
import { BigNumber, ethers } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { JsonRpcProvider } from '@ethersproject/providers'
import { Typography, Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import { BSC_ADDRESSES } from '../contracts/addresses'
import { ERC20Abi } from '../contracts/abis'





const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org/')
const BUSDABI = ERC20Abi
const { BUSD_ERC20, BURN_WALLET, OBURN_ERC20 } = BSC_ADDRESSES
const contract = new ethers.Contract(BUSD_ERC20, BUSDABI, provider)
const oburnContract = new ethers.Contract(OBURN_ERC20, BUSDABI, provider)
const { BURN_PAGE } = BSC_ADDRESSES




export const useApprove = (tokenAddress: string) => {
  const erc20 = useERC20(tokenAddress);
  const {
    state: approveState,
    send: approve,
    resetState: resetApprove,
  } = useContractFunction(erc20, "approve");
  return { approveState, approve, resetApprove };
};

/*export const useBuyOBURN = () => {
  const burnSwap = useBurnSwap();
  const {
    state: buyOBURNState,
    send: buyOBURN,
    resetState: resetBuyOBURN,
  } = useContractFunction(burnSwap, "purchaseOBURN");
  return { buyOBURNState, buyOBURN, resetBuyOBURN };
};*/

export const useVote = () => {
  const burnPage = useBurnPage();
  const {
    state: voteState,
    send: vote,
    resetState: resetVote,
  } = useContractFunction(burnPage, "vote");
  return { voteState, vote, resetVote };
};

export async function getBalance(tokenAddress: string, account: string, library: any): Promise<BigNumber> {
  const erc20 = getERC20(
    tokenAddress,
    (library as JsonRpcProvider)?.getSigner()
  );

  return await erc20.balanceOf(account as PromiseOrValue<string>);
}


export async function getCurrentCampaign(burnPageAddress: string): Promise<any> {
  const burnPage = getBurnPage(burnPageAddress);
  console.log(burnPage)
  return await burnPage.getCurrentCampaign([]);
  
}

export async function getCurrentVoters(burnPageAddress: string): Promise<any> {
  const burnPage = getBurnPage(burnPageAddress);
  console.log(burnPage)
  return await burnPage.getCurrentVoters([]);
  
}


export async function getAmountsOut(routerAddress: string, amountIn: BigNumber, token0Address: string, token1Address: string) : Promise<Array<BigNumber>> {
  const quickSwapRouter = getQuickSwapRouter(routerAddress);
  return await quickSwapRouter.getAmountsOut(amountIn, [token0Address, token1Address]);
}

export function useBalanceOf(
  tokenAddress: string,
  holderAddress: string
): BigNumber {
  const { account, library } = useEthers();

  const [result] = useCalls([
    account && {
      contract: getERC20(
        tokenAddress,
        (library as JsonRpcProvider)?.getSigner()
      ),
      method: "balanceOf",
      args: [holderAddress],
    },
  ]);

  return result?.value ? result?.value[0] : BigNumber.from(0);
}

export async function getTimes(burnPageAddress: string): Promise<any> {
  const burnPage = getBurnPage(burnPageAddress);
  var time = await burnPage.currentCampaign();
 
return time;
  
  }

  




