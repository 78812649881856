import { useMemo } from "react";
import { useEthers } from "@usedapp/core";
import { Contract, Signer, ethers } from "ethers";
import { Erc20 } from "./__generated__/Erc20";
import { ERC20Abi, BurnSwapAbi, QuickSwapRouterAbi, BurnPageAbi } from "./abis";
import { JsonRpcProvider } from "@ethersproject/providers";
import { POLYGON_ADDRESSES, BSC_ADDRESSES } from "./addresses";

const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org/");

// const provider = new ethers.providers.JsonRpcProvider("https://rpc-mainnet.maticvigil.com");




export function useERC20(address: string) {
  const { library } = useEthers();
  return useMemo(() => {
    return getERC20(address, (library as JsonRpcProvider)?.getSigner());
  }, [address, library]);
}

export function getERC20(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, ERC20Abi, signer) as Erc20;
  }
  else {
    return new Contract(address, ERC20Abi, provider) as Erc20;
  }
}

/*export function useBurnSwap() {
  const { BURN_SWAP } = POLYGON_ADDRESSES;
  const { library } = useEthers();
  return new Contract(
    BURN_SWAP,
    BurnSwapAbi,
    library
  );
}*/


export function useBurnPage() {
  const { BURN_PAGE } = BSC_ADDRESSES;
  const { library } = useEthers();
  
  return new Contract(
    BURN_PAGE,
    BurnPageAbi,
    library
  );
 
}



export function getBurnSwap(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, BurnSwapAbi, signer);
  }
  else {
    return new Contract(address, BurnSwapAbi, provider);
  }
}

export function getBurnPage(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, BurnPageAbi, signer);
  }
  else {
    return new Contract(address, BurnPageAbi, provider);
  }
}

export function getQuickSwapRouter(address: string, signer?: Signer) {
  if (signer) {
    return new Contract(address, QuickSwapRouterAbi, signer);
  }
  else {
    return new Contract(address, QuickSwapRouterAbi, provider);
  }
}

