export const POLYGON_ADDRESSES = {
 // OBURN_EXCHANGE: "0x376E0eA84760C473A30AAa04D6B615F799f10Aea",
 // OBURN_TOKEN_PRESALE: "0x5Fef7c59c45521203C1197CF793EaA2688fF9306",
 // TBURN_ERC20: "0x005dAd50D38245f764C3C4f127E72acB9b5ebb6B",
  // OBURN_ERC20: "0xdbb1b2226528bc4751a91000df4ee938886d660e",
 // OBURN_ERC20: "0x90a603fa876980b38cb6415c0328aeec6c33c3f4",
  // USDC_ERC20: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
 // QUICKSWAP_ROUTER: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
  // QUICKSWAP_ROUTER: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
 // USDC_ERC20: "0x7f8754f9cc58a8ffa69c755ae425e84b55bb3a0d",
 // UFDP_TWO_ERC20: "0x200890bF34a562fBFb9c0588D160d67950D349ad",
 // BURN_SWAP: "0x16A588D03B0F9ABaDdeFe478dCFC19337aaEff43",

};

export const BSC_ADDRESSES = {
   BURN_PAGE:"0x87F2C3C9F8DBb501A6ab3AD0559c561b9f55E37f",
// BURN_PAGE:"0xE8764BE6b4b8EAeEA52fAf18E7bA9990814185ae",
OBURN_ERC20:"0xf64c5BCfA450C1eeF223fF2B424F0Bb74153d75D",
BURN_WALLET:"0x9460B072C62Bff186f0Cec5dAC8d1376B361deBf",
USDC_ERC20:"0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
BUSD_ERC20:"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
}

export const WEBSITE_URLS = {
DEXSCREENER:"https://api.dexscreener.com/latest/dex/pairs/bsc/0x4297f816ee887a5f5dd100e7a5ce9acce64ac2a0",

}