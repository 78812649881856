import React, { useEffect, useMemo, useCallback } from 'react'
import ChooseWallet from '../ChooseWallet'
import ConnectWalletButton from '../ConnectWalletButton'
import {
  Box,
  Paper,
  ThemeProvider,
  Typography,
  Grid,
  Stack,
  Autocomplete,
  TextField,
  OutlinedInput,
  autocompleteClasses,
  outlinedInputClasses,
  Card,
  CardContent,
  Container,
  Button,
  FormHelperText,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material'

import { alpha, styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
// import { connectWallet, selectConnectingWallet } from '../../store'
import Board from '../Board'
import { useState } from 'react'
import { BSC_ADDRESSES } from '../../contracts/addresses'
import { BurnpageInterface } from '../../contracts/__generated__/Burnpage'
import { Burnpage } from '../../contracts/__generated__'
import { BurnPageAbi } from '../../contracts/abis/'
import {
  useCalls,
  useContractFunction,
  useEthers,
  TransactionStatus,
} from '@usedapp/core'
import { getBalance, useVote } from '../../contracts/functions'
import {
  TransactionResponse,
  TransactionReceipt,
} from '@ethersproject/providers'
import { Form } from 'react-router-dom'
import Wallet from '../Wallet'
import { utils, Signer } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { BigNumber } from 'ethers'
import internal from 'stream'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { chain, fromPairs } from 'lodash'
import logo from '../../images/Signature_400x100.png'
import Input from '@mui/material'
import { ethers } from 'ethers'
import { JsonRpcProvider } from '@ethersproject/providers'
import { ERC20Abi, BurnSwapAbi, QuickSwapRouterAbi } from '../../contracts/abis'
import { Erc20 } from '../../contracts/__generated__'
import { parseUnits } from 'ethers/lib/utils'
import { NumericFormat } from 'react-number-format'
import { projectMapping } from '../Profiles'
import { getTimes } from '../../contracts/functions'
import { setDefaultResultOrder } from 'dns'
import Countdown from '../Timer'
import Masonry from '@mui/lab/Masonry';
import WalletLeaderboard from '../WalletLeaderboard'
import { margin, width } from '@mui/system'

const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org/')

const { BURN_PAGE } = BSC_ADDRESSES
const BUSDABI = ERC20Abi
const { BUSD_ERC20, BURN_WALLET, OBURN_ERC20 } = BSC_ADDRESSES
const contract = new ethers.Contract(BUSD_ERC20, BUSDABI, provider)
const oburnContract = new ethers.Contract(OBURN_ERC20, BUSDABI, provider)




// Types for Typescript
interface IFormInput {
  firstName: string
  lastName: string
  value: string
  label: string
  options: string
  voteCount: string
  projectAddress: string
  chainId: { label: string; value: string }
  Id: string
  name: string
}

const BurnVote = (props) => {
  const dispatch = useDispatch()

  // const [currentAccount, setCurrentAccount]=useState()
  // const[projectAddress, setProjectAddress] = useState('')
  // const[voteCount, setvoteCount] = useState('')
  // const[chainId, setchainId] = useState('')
  const burnpageInterface = new utils.Interface(BurnPageAbi)
  const burnpageContractAddress = BSC_ADDRESSES.BURN_PAGE
  const contract = new Contract(burnpageContractAddress, burnpageInterface)
  //const contract = new Contract("0xb4963707D51539f30380C124acda89d6153Eef7d", burnpageInterface)
  const { account, library } = useEthers()

  //const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org/');
  const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545');

  const useApprove = (tokenAddress: string) => {
    const erc20 = useERC20(tokenAddress)
    const {
      state: approveState,
      send: approve,
      resetState: resetApprove,
    } = useContractFunction(erc20, 'approve')
    return { approveState, approve, resetApprove }
  }
 
 

  function useERC20(address: string) {
    const { library } = useEthers()
    return useMemo(() => {
      return getERC20(address, (library as JsonRpcProvider)?.getSigner())
    }, [address, library])
  }

  function getERC20(address: string, signer?: Signer) {
    if (signer) {
      return new Contract(address, ERC20Abi, signer) as Erc20
    } else {
      return new Contract(address, ERC20Abi, provider) as Erc20
    }
  }



  // send ({projectAddress,voteCount,chainId})
  const { approve: approveOBURN, approveState: approveOBURNState } = useApprove(BSC_ADDRESSES.OBURN_ERC20);

  const onSubmit = (e) => {
    const etherCount = ethers.utils.parseUnits(voteCount.toString(), 'ether')


if(projectAddressOption){
var project = projectAddressOption["Id"]
const lowerCaseAddress = (project.toLowerCase())
vote(etherCount, chainId, lowerCaseAddress)
}
else{
   
    console.log(projectAddress)
    const lowerCaseAddress = (projectAddress.toLowerCase())

    vote(etherCount, chainId, lowerCaseAddress)
  }
  }

  /*function useContractMethod(methodName: string) {
  const { state, send } = useContractFunction(contract, methodName, {});
  return { state, send };
}
*/

  // export default function Count() {
  // const count = useCount();
  // const { state, send: incrementCount } = useContractMethod("incrementCount");
  // const { state: setCountState, send: setCount } =
  //  useContractMethod("setCount");
  // const [input, setInput] = useState("");

  const StyledAutocomplete = styled(Autocomplete)({
    '&.Mui-focused .MuiInputLabel-outlined': {
      color: 'red',
    },
    '&.MuiAutocomplete-listbox': {
      background: 'red',
      color: 'red',
    },
  })

  const SubmitButton = styled(Button)({
    '&.MuiButton-root': {
      width: '50%',
      display: 'inline-block',
      color: 'white',
      backgroundColor: 'rgba(255,0,0,.8)',
      style: 'center',
      borderRadius: '4',
      align: 'center',
    },
  })

  const [chainId, setChainId] = useState('')
  const [projectAddress, setProjectAddress] = useState('')
  const [projectAddressOption, setProjectAddressOption] = useState<string | null>(projectMapping['Id']);
  const [voteCount, setVoteCount] = useState('')
  const [oburnBalance, setOBURNBalance] = useState(BigNumber.from(0))
  const { vote, voteState } = useVote()
  const [buyingOBURN, setBuyingOBURN] = useState(true)
  const [approving, setApproving] = useState(false)
  const [swapping, setSwapping] = useState(false)
  const [submissionMessage, setSubmissionMessage] = useState('')
  const [transactionHash, setTransactionHash] = useState('')
  const [showSubmissionPending, setShowSubmissionPending] = useState(false)
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false)
  const [showSubmissionFailure, setShowSubmissionFailure] = useState(false)
  const [networkSwitchRequested, setNetworkSwitchRequested] = useState(false)
  const [isApproved, setIsApproved] = useState(false)



  const { BURN_PAGE } = BSC_ADDRESSES

  const [now, setNow] = useState(0)
  const [end, setEnd] = useState(0)



  async function getNowEnd() {
      var times = await getTimes(BURN_PAGE)
      var now = (Math.floor(Date.now() / 1000))
      var end = await (times[1].toNumber());
      setNow(now)
      setEnd(end)
  }

  getNowEnd()




  const processApprovalStateChange = (approveState: TransactionStatus) => {
    console.log(approveState)
    if (approveState.status === 'Success') {
      setShowSubmissionSuccess(true)
      setShowSubmissionPending(false)
      setShowSubmissionFailure(false)
      setApproving(false)
      setIsApproved(true)

      setSubmissionMessage(
        'OBURN approved successfully! Click Vote to Complete Transaction',
      )

      setTransactionHash(
        approveState.transaction ? approveState.transaction.hash : '',
      )
      getUserValues()
    } else if (approveState.status === 'Exception') {
      setShowSubmissionSuccess(false)
      setShowSubmissionPending(false)
      setShowSubmissionFailure(true)
      setApproving(false)
      setSubmissionMessage(
        `Failed to approve ${buyingOBURN ? 'USDC' : 'OBURN'}: ${approveState.errorMessage
        }`,
      )
    } else if (approveState.status === 'Mining') {
      setShowSubmissionSuccess(false)
      setShowSubmissionPending(true)
      setShowSubmissionFailure(false)
      setApproving(true)
      setSubmissionMessage(`Approving OBURN...`)
      setTransactionHash(
        approveState.transaction ? approveState.transaction.hash : '',
      )
    } else if (approveState.status === 'PendingSignature') {
      setShowSubmissionSuccess(false)
      setShowSubmissionPending(true)
      setShowSubmissionFailure(false)
      setApproving(true)
      setSubmissionMessage('Waiting for User to Sign Transaction...')
      setTransactionHash('')
    }
  }

  async function getUserValues() {
    if (account && library) {
      const currOBURNBalance = await getBalance(
        BSC_ADDRESSES.OBURN_ERC20,
        account,
        library,
      )
      setOBURNBalance(currOBURNBalance)
    }
  }





  /////////////////////////////////////////////////////////

  useEffect(() => {
    processApprovalStateChange(approveOBURNState)
  }, [approveOBURNState])

  const processVoteStateChange = (voteState: TransactionStatus) => {
    console.log(voteState)
    if (voteState.status === 'Success') {
      setShowSubmissionSuccess(true)
      setShowSubmissionPending(false)
      setShowSubmissionFailure(false)
      setSwapping(false)
      setSubmissionMessage('Vote sent successfully!')
      setTransactionHash(
        voteState.transaction ? voteState.transaction.hash : '',
      )
      getUserValues()
      setIsApproved(false)
    } else if (voteState.status === 'Exception') {
      setShowSubmissionSuccess(false)
      setShowSubmissionPending(false)
      setShowSubmissionFailure(true)
      setSwapping(false)
      setSubmissionMessage(`Failed to purchase Vote: ${voteState.errorMessage}`)
    } else if (voteState.status === 'Mining') {
      setShowSubmissionSuccess(false)
      setShowSubmissionPending(true)
      setShowSubmissionFailure(false)
      setSwapping(true)
      setSubmissionMessage('Sending Vote...')
      setTransactionHash(
        voteState.transaction ? voteState.transaction.hash : '',
      )
    } else if (voteState.status === 'PendingSignature') {
      setShowSubmissionSuccess(false)
      setShowSubmissionPending(true)
      setShowSubmissionFailure(false)
      setSwapping(true)
      setSubmissionMessage('Waiting for User to Sign Transaction...')
      setTransactionHash('')
    }
  }

  useEffect(() => {
    processVoteStateChange(voteState)
  }, [voteState])


  // FORMATS OBURN VALUE WHEN USER TYPES

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: voteCount
            }
          });
        }}
        thousandSeparator=","
        suffix=' $OBURN'
      />
    );
  }




  const onChange = useCallback(
    (e, newValue) => {
      setProjectAddress(newValue);
    },
    [setProjectAddress]
  );


  const [value, setValue] = useState<string | null>(projectMapping[0].Id);
  const [inputValue, setInputValue] = useState('');




  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

<script type="text/javascript" async src="https://platform.twitter.com/widgets.js"></script>

console.log(end)
  return (
    <Box>
      
   {/* <a href="https://twitter.com/intent/tweet?text=I%20just%20voted%20in%20the%20%23burnwars%20with%20%40onlyburnsdefi%21%20Learn%20how%20the%20BurnWars%20can%20help%20your%20defi%20project%20by%20visiting%20https%3A%2F%2Fonlyburns.com%21?" data-hashtags="burnwars" >Share on Twitter</a>      
   <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
       open={showSubmissionSuccess}
        onClose={() => {
          setShowSubmissionSuccess(false)
        }}
        PaperProps={{
          style:{
            backgroundColor:'rgb(123 123 123)',
          }
        }}
      >
    <DialogTitle id="alert-dialog-title">
      {"Vote Successful"}
    </DialogTitle>
    <DialogContent>

    <DialogContentText id="alert-dialog-description">
    Congratulations on successfully voting for your favorite protocol! Share that you voted on twitter to rally more troops to the battle! <a href="https://twitter.com/intent/tweet?text=I%20just%20voted%20in%20the%20%23burnwars%20with%20%40onlyburnsdefi%21%20Learn%20how%20the%20BurnWars%20can%20help%20your%20defi%20project%20by%20visiting%20https%3A%2F%2Fonlyburns.com%21?" data-hashtags="burnwars" >Share on Twitter</a>   
          </DialogContentText>

    


          
        </DialogContent>
        



      </Dialog>
      */}
      <Snackbar
        open={showSubmissionSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowSubmissionSuccess(false)
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setShowSubmissionSuccess(false)
          }}
          severity="success"
          sx={{ width: '100%' }}
          className="mobileSnackbarTextSize"
        >
          {submissionMessage} Transaction hash:{' '}
          <a
            style={{ color: 'white' }}
            href={`https://bscscan.com/tx/${transactionHash}`}
            target="_blank"
            rel="noreferrer"
          >
            {transactionHash}
          </a>
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSubmissionFailure}
        autoHideDuration={6000}
        onClose={() => {
          setShowSubmissionFailure(false)
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setShowSubmissionFailure(false)
          }}
          severity="error"
          sx={{ width: '100%' }}
          className="mobileSnackbarTextSize"
        >
          {submissionMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSubmissionPending}
        autoHideDuration={20000}
        onClose={() => {
          setShowSubmissionPending(false)
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => {
            setShowSubmissionPending(false)
          }}
          severity="info"
          sx={{ width: '100%' }}
          className="mobileSnackbarTextSize"
        >
          {submissionMessage}{' '}
          {transactionHash && (
            <b>
              Transaction hash:{' '}
              <a
                style={{ color: 'white' }}
                href={`https://bscscan.com/tx/${transactionHash}`}
                target="_blank"
                rel="noreferrer"
              >
                {transactionHash}
              </a>
            </b>
          )}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>


        <Grid
          container
          paddingBottom={"4%"}
          paddingTop={"1%"}
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
        >
          <Link href="https://onlyburns.com/" target="_blank" rel="noreferrer">
            <img style={{ maxWidth: '100%' }} src={logo} alt="OnlyBurns.com"></img>
          </Link>
        </Grid>




        <Box  justifyContent="center"
          alignItems="center"
        sx={{
          padding:"1%",
          marginRight: -5,
          width: "100%"
        }}>
          
          <Masonry columns={{md:2,sm:1}} spacing={3} sx={{alignItems:"center", width:"auto"}} >
          
            {/* Vote Cast */}
            <Grid item lg={6} md={12}>
              <Box sx={{ }}>
                <Card
                  sx={{
                    border: '2px solid grey',
                    p: '2%',

                    background: 'rgba(50,50,50,0.8)',
                    borderRadius: '1.5em',
                    width: '100%',
                  }}
                >
                  <CardContent>

                    <Box >



                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={0}
                        padding={1}
                      >

                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={0}
                        >
                          
{ end < 9999999999 &&
 <><Typography variant='body2' color={'red'} sx={{fontWeight: 'bold'}}>Remaining Voting Time </Typography>
                          <Card
                            sx={{
                              border: '2px solid rgba(200,200,200,.8)',
                              p: '2%',
                              height: '100%',
                              background: 'rgba(200,200,200,0.6)',
                              borderRadius: '.5em',
                              width: '100%',
                              justifyContent: 'center',
                              display: 'flex'
                            }}
                          >
                            <Countdown></Countdown>
                          </Card></>}
                          

                        </Stack>
                        <ConnectWalletButton></ConnectWalletButton>
    
                      </Stack>


                    </Box>

                    <Grid container paddingTop={0}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h4"
                          align="center"
                          pb={7}
                          pt={4}
                          sx={{ fontWeight: 'bold' }}
                        >

                          Cast Your Vote!{''}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          container
                          display={'flex'}
                          spacing={5}
                          alignItems={'center'}
                          width={'100%'}
                        >
                          {/* //Step 1*/}
                          <Grid item xs={12} display="flex" alignItems={'center'}>
                            <Typography
                              noWrap

                              color="white"
                              variant="h5"
                              pr='4%'
                              display='inline-block'
                            >
                              Step 1:&nbsp;
                            </Typography>
                            <FormControl sx={{}}>
                              <Select
                                sx={{
                                  borderRadius: ".5em",
                                  bgcolor: 'black',
                                  color: 'white',
                                  '.MuiOutlinedInput-notchedOutline': {
                                    border: '3px solid',
                                    borderColor: 'rgba(250, 250, 250, 1)',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 1)',
                                  },
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 1)',
                                  },
                                  '.MuiSvgIcon-root ': {
                                    fill: 'white !important',
                                  },
                                  '& #my-simple-select': {
                                    fontSize: '1rem',
                                  }

                                }}
                                placeholder="Choose Network"
                                onChange={(e) => setChainId(e.target.value)}
                                value={chainId}
                                id="my-simple-select"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      bgcolor: 'grey',
                                    },
                                  },
                                }}
                              >
                                <MenuItem value={137}>Polygon</MenuItem>
                                <MenuItem value={56}>BSC</MenuItem>
                                <MenuItem value={1}>Ethereum</MenuItem>
                                <MenuItem value={42161}>Arbitrum One</MenuItem>
                                <MenuItem value={10}>Optimism</MenuItem>
                                <MenuItem value={250}>Fantom Opera</MenuItem>
                                <MenuItem value={1284}>Moonbeam</MenuItem>
                                <MenuItem value={1285}>Moonriver</MenuItem>
                                <MenuItem value={42262}>Oasis Emerald</MenuItem>
                                <MenuItem value={43114}>Avalanche</MenuItem>
                                <MenuItem value={9999}>Solana</MenuItem>
                              </Select>
                              <FormHelperText>Select Target Network</FormHelperText>
                            </FormControl>
                          </Grid>

                          {/*  //Step 2*/}
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems={'center'}
                            whiteSpace="nowrap"
                          >
                            <Typography color="white" variant="h5" pr="4%">
                              Step 2:
                            </Typography>



                            <StyledAutocomplete
                              freeSolo
                             
                              disablePortal
                              onChange={(event : any, value:any) => {setProjectAddressOption(value)}}
                              onInputChange={(event : any, newInputValue : string) => {
                                setProjectAddress(newInputValue);
                              }}
                              value={projectAddressOption}
                              inputValue={projectAddress}
                              options={projectMapping}
                              getOptionLabel={(option: any) => option.Id  ? option.Id : ""}
                              PaperComponent={({ children }) => (
                                <Paper style={{ background: "grey" }}>{children}</Paper>)}
                              sx={{
                                width: "100%", maxWidth: "600px",
                                '& + .MuiAutocomplete-popper .root': {
                                  backgroundColor: 'grey',
                                },
                              }}
                              renderOption={(props, option: any) => (
                                <Box component="li" sx={{}} {...props}>
                                  {option.name} | {`${option.Id.slice(0, 6)}...${option.Id.slice(-7)}`}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}

                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: ".5em",

                                     
                                    },
                                    "& .MuiAutocomplete-inputRoot": {

                                      backgroundColor: 'black'
                                    },

                                  }}
                                  helperText="Select or paste protocol address"
                                />)}
                            />

                          </Grid>
                          {/*   //Step 3*/}

                          <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems={'center'}
                            whiteSpace="nowrap"
                          >
                            <Typography color="white" variant="h5" pr="4%">
                              Step 3:
                            </Typography>



                            <TextField

                              sx={{
                                input: {
                                  borderRadius: '.5em',
                                  background: "black"
                                },
                                "& .MuiOutlinedInput-root": {
                                  legend: {
                                    marginLeft: "30px"
                                  }
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderRadius: ".5em"
                                },
                                "& .MuiInputLabel-outlined": {
                                  paddingLeft: "20px"
                                }
                              }}
                              label={''}
                              variant="outlined"
                              helperText="Enter amount of OBURN"
                              onChange={(e) => setVoteCount(e.target.value)}
                              value={voteCount}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          padding={4}
                          direction="column"
                          display="flex"
                          justifyContent="center"
                          alignContent="center"
                        >
                          <Grid item xs={12}>
                            <Typography color="red" variant="h5" align="center">
                              Note: This will burn the OBURN
                            </Typography>
                            <Grid item xs={12}>
                              <Box textAlign={'center'}>
                                {isApproved && (
                                  <SubmitButton onClick={onSubmit}>
                                    Burn Vote
                                  </SubmitButton>
                                )}

                                {!isApproved && (
                                  <SubmitButton
                                    disabled={!account}
                                    onClick={() => {
                                      approveOBURN(
                                        BSC_ADDRESSES.BURN_PAGE,
                                        parseUnits(voteCount.toString(), 18),
                                      )
                                    }}
                                  >
                                    {' '}
                                    Approve
                                  </SubmitButton>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            {/* Burn Board */}
            <Grid item md={6} xs={12}>
              <Box sx={{  }}>
                <Card
                  sx={{
                    border: '2px solid grey',

                    p: '2%',
                    height: '100%',
                    background: 'rgba(57,63,76,0.8)',
                    borderRadius: '1.5em',
                    width: '100%',
                  }}
                >
                  <CardContent>
                    <Board></Board>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
              {/* Wallet Board */}
              <Grid item md={6} xs={12}>
              <Box sx={{  }}>
                <Card
                  sx={{
                    border: '2px solid grey',

                    p: '2%',
                    height: '100%',
                    background: 'rgba(57,63,76,0.5)',
                    borderRadius: '1.5em',
                    width: '100%',
                  }}
                >
                  <CardContent>
                    <WalletLeaderboard></WalletLeaderboard>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Masonry>
        
        </Box>
      </Container>
    </Box>

  )
}



export default BurnVote
