import { Grid } from '@mui/material';
import * as React from 'react';
import Profiles from '../Profiles';
import Typography from '@mui/material/Typography';
import {useState} from 'react'
import { ERC20Abi } from '../../contracts/abis';
import { BSC_ADDRESSES } from '../../contracts/addresses';
import { ethers } from 'ethers';
import { useEffect } from 'react';
import { format } from '../../components/Profiles';
import { formatUnits, parseUnits} from 'ethers/lib/utils';
import { red } from '@mui/material/colors';
import WalletProfiles from '../WalletProfiles';


const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org/");


const BUSDABI = ERC20Abi;
const {BUSD_ERC20, BURN_WALLET} = BSC_ADDRESSES;
const contract = new ethers.Contract(BUSD_ERC20, BUSDABI, provider);





export default function WalletLeaderboard() {






    return (
      <Grid container >
        <Grid item xs={12}>
       
        <Typography color='rgb(237,28,36)' variant='h3' align='center' pb='3%' sx={{fontWeight:"bold"}}> Top Voters </Typography>
        </Grid>
        <Grid item xs={12}>
         <WalletProfiles></WalletProfiles>
         </Grid>
      </Grid>

    );
  }

