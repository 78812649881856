import * as React from 'react'
import { useState, useMemo, useEffect } from 'react'
import { useApprove, getBalance } from '../../contracts/functions'
import { BigNumber, ethers } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useEthers } from '@usedapp/core'
import { configs } from '../../config'
import { POLYGON_ADDRESSES, WEBSITE_URLS } from '../../contracts/addresses'
import { JsonRpcProvider } from '@ethersproject/providers'
import { Typography, Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import { getCurrentVoters } from '../../contracts/functions'
import { BSC_ADDRESSES } from '../../contracts/addresses'
import { ERC20Abi } from '../../contracts/abis'
import { indexOf } from 'lodash'

// import { Board }  from '../Board/'
// import { FetchBurnWallet } from '../Board/'

const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org/')
const BUSDABI = ERC20Abi
const { BUSD_ERC20, BURN_WALLET, OBURN_ERC20 } = BSC_ADDRESSES
const contract = new ethers.Contract(BUSD_ERC20, BUSDABI, provider)
const oburnContract = new ethers.Contract(OBURN_ERC20, BUSDABI, provider)
const { BURN_PAGE } = BSC_ADDRESSES
const { DEXSCREENER } = WEBSITE_URLS



export interface profilesProps {
  getburn: number
  fetchBurnWallet: (...args: any[]) => any
  Board: (...args: any[]) => any
  el: any
  allData: any
  price: any
}

export default function WalletProfiles() {
  const [currentCampaignData, setCurrentCampaignData] = useState([] as any[])
  const [oburnPrice, setOburnPrice] = useState(0)


  const fetchCurrentCampaign = async () => {
    var currentCampaign = await getCurrentVoters(BURN_PAGE)

    var data = currentCampaign.map((e) => {
      return { ...e }
    })

    setCurrentCampaignData(data)
  }

  useEffect(() => {
    fetchCurrentCampaign()
  }, [])

  async function getOBURNprice() {
    try {
      let BUSDprice = 1;

      let OBURNbalance = await oburnContract.balanceOf("0x4297f816Ee887a5f5dD100e7A5ce9AcCE64AC2a0");

      let BUSDbalance = await contract.balanceOf("0x4297f816Ee887a5f5dD100e7A5ce9AcCE64AC2a0");

      let x = BUSDbalance / OBURNbalance;
      let y = x * BUSDprice;

      let OBURNprice = y;

      setOburnPrice(OBURNprice)
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getOBURNprice()
  }, [])


  function sortData(d1, d2) {
    if (
      parseFloat(formatUnits(d1.voterAmount, 18)) <
      parseFloat(formatUnits(d2.voterAmount, 18))
    ) {
      return 1
    } else {
      return -1
    }
  }

  var sortedData = [...currentCampaignData].sort(sortData)


  var topTen = sortedData.slice(0, 10)

  console.log(topTen)

  return <div>{Item(topTen, oburnPrice)}</div>
}

export function format(value) {
  const formatted = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value)

  return formatted
}



export function dollarFormat(value) {
  const formatted = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 0,
  }).format(value)

  return formatted
}




function Item(array, oburnPrice) {

  return (
    <>
      <Grid container direction={'row'} item sm={12}>

      </Grid>
      {/* Title Card */}
      <Grid
        container
        display={'flex'}
        spacing={2}
        alignItems={'stretch'}

      >
        <Grid item xs={12} display="flex" alignItems={'stretch'}>
          <Card
            sx={{
              border: '1px solid grey',
              p: '2%',
              height: '100%',
              background: 'rgba(5,5,5,0.7)',
              borderRadius: '.5em',
              width: '100%',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"

            >
              <Grid
                item
                width={'33%'}
                maxWidth={'33%'}
                sm={4}
                zeroMinWidth
                display={'flex'}
                flexDirection={'row'}

              >
                <Grid
                  container
                  alignItems={'center'}
                  direction={'column'}
                  justifyContent={'center'}
                  item
                  sm
                  zeroMinWidth
                >
                  <Typography variant="h6">
                    Wallet
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                sm={4}
                height="100%"
                zeroMinWidth
                display={'flex'}
                flexDirection={'row'}
                maxWidth={'33%'}
                width={'33%'}
              >
                <Grid
                  container
                  alignItems={'center'}
                  direction={'column'}
                  justifyContent={'center'}
                  item
                  sm
                  zeroMinWidth
                >

                  <Typography variant="h6" sx={{}}>
                    Tokens Burnt
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                sm={4}
                height="100%"
                zeroMinWidth
                display={'flex'}
                flexDirection={'row'}
                maxWidth={'33%'}
                width={'33%'}
              >
                <Grid
                  container
                  alignItems={'center'}
                  direction={'column'}
                  justifyContent={'center'}
                  item
                  sm
                  zeroMinWidth
                >

                  <Typography variant="h6" align='center'>
                    Value Burnt
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {/* Wallet Info Cards */}
      {[...array].map((value) => (
        <Grid
          container
          display={'flex'}
          spacing={2}
          alignItems={'stretch'}
          key={value.voterAddress}
        >
          <Grid item xs={12} display="flex" alignItems={'stretch'}>
            <Card
              sx={{
                border: '1px solid grey',
                p: '2%',
                height: '100%',
                background: 'rgba(5,5,5,0.5)',
                borderRadius: '.5em',
                width: '100%',
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid
                  item
                  sm={4}
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    <Typography variant="body2">
                      {value.voterAddress.slice(0, 6)}...{value.voterAddress.slice(-7)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={4}
                  height="100%"
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >


                    <Typography variant="body2">
                      {format(ethers.utils.formatUnits(value.voterAmount, 18))} $OBURN
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={4}
                  height="100%"
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >

                    <Typography variant="body2">
                      ${dollarFormat(oburnPrice * (parseInt(ethers.utils.formatUnits(value.voterAmount, 18))))} BUSD value

                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
