import ERC20 from "./erc20.json";
import BurnSwap from "./BurnSwap.json";
import Burnpage from "./Burnpage.json"
import QuickSwapRouter from "./IUniswapV2Router02.json";


export const ERC20Abi = ERC20.abi;
export const BurnSwapAbi = BurnSwap.abi;
export const QuickSwapRouterAbi = QuickSwapRouter.abi;
export const BurnPageAbi = Burnpage.abi;
