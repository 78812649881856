import React, { useEffect, useMemo, useCallback } from 'react'
import ChooseWallet from '../ChooseWallet'
import {
    Typography,
    Grid,
    Stack,
} from '@mui/material'
import { useState } from 'react'
import { BSC_ADDRESSES } from '../../contracts/addresses'
import { getTimes } from '../../contracts/functions'


console.log(BSC_ADDRESSES)


export default function Countdown() {
    const [now, setNow] = useState(0)
    const [end, setEnd] = useState(0)

    const { BURN_PAGE } = BSC_ADDRESSES

    async function getNowEnd() {
        var times = await getTimes(BURN_PAGE)
        var now = (Math.floor(Date.now() / 1000))
        var end = await (times[1].toNumber());
        setNow(now)
        setEnd(end)
    }

    getNowEnd()

if (now > end) {
    var remain = 0
}
else {
    var remain = end - now
}




    var d = Math.floor(remain / (3600 * 24));

    var h = Math.floor(remain % (3600 * 24) / 3600);

    var m = Math.floor(remain % 3600 / 60);

    var s = Math.floor(remain % 60);


    const [days, setDays] = useState(d);
    const [hours, setHours] = useState(h);
    const [minutes, setMinutes] = useState(m);
    const [seconds, setSeconds] = useState(s);
    useEffect(() => {
        let myInterval = setInterval(() => {
            setDays(d)
            setHours(h);
            setMinutes(m);
            setSeconds(s);
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });



    const zeroPad = (num, places) => String(num).padStart(places, '0')
    
    return <><Stack direction="row">
        <Stack alignItems={'center'} justifyContent={'center'} display={'flex'}>
            <Typography variant="body2" width={'1.4em'}  >
                {zeroPad(days.toString(), 2)}
            </Typography>
            <Typography variant="body2">
                D
            </Typography>
        </Stack>
        <Stack alignItems={'center'}>
            <Typography variant="body2" width={'1.4em'} textAlign="center" >
                {zeroPad(hours.toString(), 2)}
            </Typography>
            <Typography variant="body2">
                H
            </Typography>
        </Stack>
        <Stack alignItems={'center'}>
            <Typography variant="body2" width={'1.4em'} textAlign="center" >
                {zeroPad((minutes.toString()), 2)}
            </Typography>
            <Typography variant="body2">
                M
            </Typography>
        </Stack>
        <Stack alignItems={'center'}>
            <Typography variant="body2" width={'1.4em'} textAlign="center" >
                {zeroPad((seconds.toString()), 2)}
            </Typography>

            <Typography variant="body2">
                S
            </Typography>
        </Stack>
    </Stack></>


};