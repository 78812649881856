import * as React from 'react'
import { useState, useMemo, useEffect } from 'react'
import { useApprove, getBalance } from '../../contracts/functions'
import { BigNumber, ethers } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useEthers } from '@usedapp/core'
import { configs } from '../../config'
import { POLYGON_ADDRESSES, WEBSITE_URLS } from '../../contracts/addresses'
import { JsonRpcProvider } from '@ethersproject/providers'
import { Typography, Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/system'
import { getCurrentCampaign } from '../../contracts/functions'
import { BSC_ADDRESSES } from '../../contracts/addresses'
import { ERC20Abi } from '../../contracts/abis'
import { indexOf } from 'lodash'

// import { Board }  from '../Board/'
// import { FetchBurnWallet } from '../Board/'


const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org/')
const BUSDABI = ERC20Abi
const { BUSD_ERC20, BURN_WALLET, OBURN_ERC20 } = BSC_ADDRESSES
const contract = new ethers.Contract(BUSD_ERC20, BUSDABI, provider)
const oburnContract = new ethers.Contract(OBURN_ERC20, BUSDABI, provider)
const { BURN_PAGE } = BSC_ADDRESSES
const { DEXSCREENER } = WEBSITE_URLS

const HeaderTypography = styled(Typography)({
  '&.MuiTypography-root': {
    color: 'white',
    noWrap: 'true',
  },
})




export interface profilesProps {
  getburn: number
  fetchBurnWallet: (...args: any[]) => any
  Board: (...args: any[]) => any
  el: any
  allData: any
  price:any
}

export default function Profiles() {
  const [currentCampaignData, setCurrentCampaignData] = useState([] as any[])
  const [burnWalletBalance, setBurnWalletBalance] = useState(0)
  const [oburnPrice, setOburnPrice] = useState(0)





  
  const fetchCurrentCampaign = async () => {
    var currentCampaign = await getCurrentCampaign(BURN_PAGE)

    var data = currentCampaign.map( (e) => {
      return {...e}
    })

    setCurrentCampaignData(data)
  }

  useEffect(() => {
    fetchCurrentCampaign()
  }, [])

  const fetchBurnWallet = async () => {
    let _balance = await contract.balanceOf(BURN_WALLET)
    const balance = parseFloat(formatUnits(_balance, 18))
    setBurnWalletBalance(balance)

   
  }

  useEffect(() => {
    fetchBurnWallet()
  }, [])



  async function getOBURNprice() {
    try {
        let BUSDprice = 1;
  
        let OBURNbalance = await oburnContract.balanceOf("0x4297f816Ee887a5f5dD100e7A5ce9AcCE64AC2a0");
  
        let BUSDbalance = await contract.balanceOf("0x4297f816Ee887a5f5dD100e7A5ce9AcCE64AC2a0");
  
        let x = BUSDbalance / OBURNbalance;
        let y = x * BUSDprice;
  
        let OBURNprice = y;
  
        setOburnPrice(OBURNprice)
    }
    catch (error) {
        console.error(error);
    }
  }
  
  useEffect(() => {
    getOBURNprice()
  }, [])


  function sortData(d1, d2) {
    if (
      parseFloat(formatUnits(d1.voteCount, 18)) <
      parseFloat(formatUnits(d2.voteCount, 18))
    ) {
      return 1
    } else {
      return -1
    }
  }

  var sortedData = [...currentCampaignData].sort(sortData)


var mapData = sortedData.map(value => {

  for (let i = 0; i < sortedData.length; i++) {
    
    if(i==0) {
    sortedData[i].newAttribute = burnWalletBalance *.6
  }
  else if(i==1) {
    sortedData[i].newAttribute = burnWalletBalance *.2
  }
 else if(i==2) {
    sortedData[i].newAttribute = burnWalletBalance *.10
  }
 else if(i==3) {
    sortedData[i].newAttribute = burnWalletBalance *.06
  }
 else if(i==4) {
    sortedData[i].newAttribute = burnWalletBalance *.04
  }
  else
  sortedData[i].newAttribute = 0
}

return value
})



  return <div>{Item(mapData, burnWalletBalance, oburnPrice)}</div>
}

export function format(value) {
  const formatted = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value)

  return formatted
}



export function dollarFormat(value) {
  const formatted = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 0,
  }).format(value)

  return formatted
}



function sortData(d1, d2) {
  if (
    parseFloat(formatUnits(d1.voteCount, 18)) <
    parseFloat(formatUnits(d2.voteCount, 18))
  ) {
    return 1
  } else {
    return -1
  }
}

// This should return project address if there is no project name
function addressToName(value) {
  var searchKey = value.projectAddress.toLowerCase() 
  var selectedData = projectMapping[projectMapping.map(function (item) { return item.Id; }).indexOf(searchKey)];

  return selectedData
    ? selectedData.name
    : `${value.projectAddress.slice(0, 6)}...${value.projectAddress.slice(-7)}`
}




export var projectMapping = [
  { Id: '0xf64c5bcfa450c1eef223ff2b424f0bb74153d75d', name: 'OnlyBurns'},
  { Id: '0x62f594339830b90ae4c084ae7d223ffafd9658a7', name: 'Sphere Finance' },
  { Id: '0x29abc4d03d133d8fd1f1c54318428353ce08727e', name: 'Kyoto Swap' },
  { Id: '0x3b248cefa87f836a4e6f6d6c9b42991b88dc1d58', name: 'EMP Money' },
  { Id: '0xce18fbbad490d4ff9a9475235cfc519513cfb19a', name: 'Vertek Dex'  },
  { Id: '0xf2fab804c4bbd38cdbbf20394dfd223095eda0ae', name:'Warfi' },
  { Id: '0x5376a83112100ff1567b2782e0d99c6d949b5509', name:'Up Finance'  },
  { Id:  '0xea97c7c1c89d4084e0bfb88284fa90243779da9f', name:'CryptoLink - PAPER' },
  { Id:  '0x87a5c9b60a3aaf1064006fe64285018e50e0d020', name:'World of Magik' },
  { Id:  '0x7a364484303b38bce7b0ab60a20da8f2f4370129', name:'SMB Swap' },
  { Id:  '0xa0105d7fc6190598523f568001a71164341b0a22', name:'Subzero Plus' },
  { Id:  '0x60eec374a1ba3907e9bdd8a74ce368d041d89c79', name:'Parrotly' },
  { Id:  '0xc250e9987a032acac293d838726c511e6e1c029d', name:'OtterClam' },
  { Id:  '0x0557a288a93ed0df218785f2787dac1cd077f8f3', name:'MDB' },
  { Id:  '0x4e3cabd3ad77420ff9031d19899594041c420aee', name:'Titano' },
  { Id:  '0x8f47416cae600bccf9530e9f3aeaa06bdd1caa79', name:'Thorfi' },
  { Id:  '0x0ed82fae72b6833ce9cf1928128fc828f5c59e77', name:'Uniqo' },
  { Id:  '0x553a4ac2c34ad2f4c8769b013411bd94bf39fcb0', name:'Ferro Cards' },
  { Id: '0x5541d83efad1f281571b343977648b75d95cdac2', name:'Grape Finance'},
  { Id: '0x06bdc702fb8af5af8067534546e0c54ea4243ea9', name:'Fury of the Fur'},
  { Id: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a', name:'TitanDao'},
  { Id: '0x0731d0c0d123382c163aae78a09390cad2ffc941', name:'CryptoLink - INK'},
  { Id: '0x6b0a03c7bd8441e0f8959394761e29bd6afbfdf7', name:'ONE Token'},
]





function Item(array, burnWalletBalance,oburnPrice) {

  return (
    <>
      <Grid container direction={'row'} item sm={12}>
        <Typography variant="h5">Burn Wallet Amount: </Typography>

        <Typography variant="h5" color="red" sx={{ fontWeight: 'bold' }}>
          &nbsp;${format(burnWalletBalance)}
        </Typography>
      </Grid>
      {/* Title Card */}
      <Grid
          container
          display={'flex'}
          spacing={2}
          alignItems={'stretch'}
          
        >
          <Grid item xs={12} display="flex" alignItems={'stretch'}>
            <Card
              sx={{
                border: '1px solid grey',
                p: '2%',
                height: '100%',
                background: 'rgba(5,5,5,0.7)',
                borderRadius: '.5em',
                width: '100%',
              }}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                
              >
                <Grid
                  item
                  width={'33%'}
                  maxWidth={'33%'}
                  sm={4}
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    <Typography variant="h6">
                      Protocol
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={4}
                  height="100%"
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    
                    <Typography variant="h6" sx={{}}>
                    Total Votes
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={4}
                  height="100%"
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    
                    <Typography variant="h6" align='center'>
                      Current Position Winnings
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
{/* Project Info Cards */}
      {[...array].map((value) => (
        <Grid
          container
          display={'flex'}
          spacing={2}
          alignItems={'stretch'}
          key={value.projectAddress}
        >
          <Grid item xs={12} display="flex" alignItems={'stretch'}>
            <Card
              sx={{
                border: '1px solid grey',
                p: '2%',
                height: '100%',
                background: 'rgba(5,5,5,0.5)',
                borderRadius: '.5em',
                width: '100%',
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid
                  item
                  sm={4}
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    <Typography variant="body2">
                      {addressToName(value)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={4}
                  height="100%"
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    
                    <Typography variant="body2">
                      {format(ethers.utils.formatUnits(value.voteCount, 18))} Votes
                    </Typography>

                    <Typography variant="body2">
                    ${dollarFormat(oburnPrice*(parseInt(ethers.utils.formatUnits(value.oburnAmount, 18))))} OBURN value
                    </Typography>

                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={4}
                  height="100%"
                  zeroMinWidth
                  display={'flex'}
                  flexDirection={'row'}
                  maxWidth={'33%'}
                  width={'33%'}
                >
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    item
                    sm
                    zeroMinWidth
                  >
                    
                    <Typography variant="body2">
                      ${format(value.newAttribute)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
